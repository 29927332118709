import React from 'react';
import AppRouter from "components/routing/AppRouter";

class App extends React.Component {
    render() {
        return (
            <AppRouter/>
        );
    }
}

export default App;
